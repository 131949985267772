import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import PromoBlurb from '../PromoBlurb'

const FactorialHeader = ({ featured }: { featured?: boolean }) => {
  const data = useStaticQuery(query)
  const photo = data.hero.childImageSharp.gatsbyImageData
  return (
    <PromoBlurb
      photo={photo}
      company="Factorial"
      heading="Factorial drives continuous improvement during rapid growth"
      subheading="In their first year of using Swarmia, Factorial more than doubled their throughput and increase code review rate from 74% to 95%. They also cut down the average time to first review from 12 hours to 10 hours and reduced merge time from 24 to 15 hours."
      featured={featured}
    />
  )
}

export default FactorialHeader

const query = graphql`
  {
    hero: file(
      relativePath: { eq: "images/story/factorial/factorial-hero.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 480, height: 540, layout: FIXED)
      }
    }
  }
`
