import React from 'react'
import styled from 'styled-components'
import bg from '../../assets/images/story/wolt/results-bg.svg'
import theme from '../../theme'

const Results = ({
  results,
}: {
  results: { name: string; value: number; dir: 'up' | 'down' }[]
}) => {
  return (
    <Container>
      {results.map(i => (
        <Value key={i.name} {...i} />
      ))}
    </Container>
  )
}
export default Results

const Value = ({
  name,
  value,
  dir,
}: {
  name: string
  value: number
  dir: 'up' | 'down'
}) => {
  return (
    <ValueContainer>
      <div className="label">{name}</div>
      <h1>
        <span>{dir === 'down' ? '↓' : '↑'}</span> {value}%
      </h1>
    </ValueContainer>
  )
}

const Container = styled.div`
  display: flex;
  gap: 64px;
  min-height: 356px;
  margin-top: 70px;
  margin-bottom: 48px;
  align-items: center;
  justify-content: center;
  background-image: url(${bg});
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  @media screen and (max-width: ${theme.breakpoints['large']}) {
    flex-direction: column;
    gap: 0;
    margin-top: 0;
    padding-top: 48px;
    height: auto;
  }
`

const ValueContainer = styled.div`
  span {
    color: ${theme.colors.green600};
  }
  h1,
  span {
    font-size: 4rem;
  }
`
