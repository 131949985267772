import React from 'react'
import Results from '../Results'

const FactorialResults = () => {
  return (
    <Results
      results={[
        { name: 'throughput', value: 112, dir: 'up' },
        { name: 'review time', value: 17, dir: 'down' },
        { name: 'merge time', value: 38, dir: 'down' },
      ]}
    />
  )
}
export default FactorialResults
