import { graphql, useStaticQuery } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'
import React from 'react'
import MetaImage from '../assets/images/engineering-effectiveness-meta.png'
import tag from '../assets/images/tags/engineering-effectiveness.svg'
import FastIcon from '../assets/images/product/blurb-icons/fast.svg'
import LockIcon from '../assets/images/product/blurb-icons/lock.svg'
import HeartIcon from '../assets/images/product/blurb-icons/heart.svg'
import Layout from '../components/Layout'
import { gridSpacing } from '../components/blocks/ColumnsBlock'
import { DarkLogoBlock } from '../components/blocks/DarkLogoBlock'
import HeroBlock from '../components/blocks/HeroBlock'
import LeadBlock from '../components/blocks/LeadBlock'
import QuoteMosaicBlock from '../components/blocks/QuoteMosaicBlock'
import QuoteBlock, { quotes } from '../components/blocks/QuoteBlock'
import FactorialPromo from '../components/story/factorial/Promo'
import FactorialResults from '../components/story/factorial/Results'
import Box from '../components/primitives/Box'
import Row from '../components/primitives/Row'
import Stack from '../components/primitives/Stack'
import Block from '../components/primitives/Block'
import Blurb from '../components/system/Blurb'
import CTABlock from '../components/blocks/CTABlock'
import Br from '../components/system/Br'
import G2Testimonials from '../components/G2Testimonials'

import { responsiveScale } from '../styles/helpers'

export const query = graphql`
  {
    backgroundImage: file(relativePath: { eq: "images/backgrounds/sky.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, quality: 99)
      }
    }
    mosaicRight: file(relativePath: { eq: "images/mosaic/balance.png" }) {
      childImageSharp {
        gatsbyImageData(width: 650)
      }
    }
    mosaicLeft: file(relativePath: { eq: "images/mosaic/work-log.png" }) {
      childImageSharp {
        gatsbyImageData(width: 650)
      }
    }
    mosaicRight2: file(
      relativePath: { eq: "images/mosaic/survey-narrow.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 650)
      }
    }
    mosaicRight3: file(relativePath: { eq: "images/mosaic/blue-surveys.png" }) {
      childImageSharp {
        gatsbyImageData(width: 650)
      }
    }
    mosaicLeft2: file(
      relativePath: { eq: "images/mosaic/blue-investment.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 650)
      }
    }
    mosaicRight4: file(
      relativePath: { eq: "images/mosaic/navy-notifications.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 650)
      }
    }
    scale: file(
      relativePath: { eq: "images/home/every-team-is-different.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 612)
      }
    }
    integrations: file(
      relativePath: { eq: "images/enterprise/integrations.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 670)
      }
    }
  }
`
const EffectivenessPage = () => {
  const data = useStaticQuery(query)

  return (
    <Layout
      title="Improve business outcomes, developer productivity, and experience"
      variant="dark"
      isNew
      description="Get the visibility you need to build the right things faster. Swarmia serves your whole engineering organization, from developers to managers and from directors to VPs."
      metaImage={MetaImage}
    >
      <HeroBlock
        tag={tag}
        title={
          <>Improve business outcomes, developer productivity, and experience</>
        }
        content="Get the visibility you need to build the right things faster. Swarmia serves your whole engineering organization, from developers to managers and from directors to VPs."
        backgroundImage={getImage(data.backgroundImage)!}
        secondaryButton={null}
      />
      <DarkLogoBlock />
      <LeadBlock heading="Put your team on a path of continuous improvement across three key areas" />
      <QuoteMosaicBlock
        title="Take the guesswork out of decision-making"
        content={
          <Stack>
            <Stack space={16}>
              <Box>
                CTOs, VPEs, and engineering directors use Swarmia to make
                decisions that move the business in the right direction.
                <br />
                <br />
              </Box>
            </Stack>
            <Row space={12}>
              <Box font="large" color="dataPink">
                *
              </Box>
              <Box font="small" color="black">
                Understand where engineering time goes
              </Box>
            </Row>
            <Row space={12}>
              <Box font="large" color="dataPink">
                *
              </Box>
              <Box font="small" color="black">
                Stay on top of cross-team initiatives & catch delivery risks
                early
              </Box>
            </Row>
            <Row space={12}>
              <Box font="large" color="dataPink">
                *
              </Box>
              <Box font="small" color="black">
                Create audit-ready software capitalization reports
              </Box>
            </Row>
          </Stack>
        }
        imageAlign="right"
        image={getImage(data.mosaicRight)!}
        quote={quotes.luca}
      />
      <QuoteMosaicBlock
        title="Systematically improve developer productivity"
        content={
          <Stack>
            <Stack space={16}>
              <Box>
                Engineering directors and managers use Swarmia to identify and
                eliminate software delivery bottlenecks.
                <br />
                <br />
              </Box>
            </Stack>
            <Row space={12}>
              <Box font="large" color="dataPink">
                *
              </Box>
              <Box font="small" color="black">
                Measure healthy productivity metrics at the team level
              </Box>
            </Row>
            <Row space={12}>
              <Box font="large" color="dataPink">
                *
              </Box>
              <Box font="small" color="black">
                Identify and fix common teamwork anti-patterns
              </Box>
            </Row>
            <Row space={12}>
              <Box font="large" color="dataPink">
                *
              </Box>
              <Box font="small" color="black">
                Visualize the work of software engineers
              </Box>
            </Row>
          </Stack>
        }
        imageAlign="left"
        image={getImage(data.mosaicLeft)!}
        quote={quotes.martin2}
      />
      <QuoteMosaicBlock
        title="Create better developer experience"
        content={
          <Stack>
            <Stack space={16}>
              <Box>
                Software teams use Swarmia to continuously improve the
                experience of shipping software.
                <br />
                <br />
              </Box>
            </Stack>
            <Row space={12}>
              <Box font="large" color="dataPink">
                *
              </Box>
              <Box font="small" color="black">
                Adopt team-wide working agreements
              </Box>
            </Row>
            <Row space={12}>
              <Box font="large" color="dataPink">
                *
              </Box>
              <Box font="small" color="black">
                Automate feedback loops with Slack notifications
              </Box>
            </Row>
            <Row space={12}>
              <Box font="large" color="dataPink">
                *
              </Box>
              <Box font="small" color="black">
                Dramatically reduce CI wait times
              </Box>
            </Row>
            <Row space={12}>
              <Box font="large" color="dataPink">
                *
              </Box>
              <Box font="small" color="black">
                Run developer experience surveys
              </Box>
            </Row>
          </Stack>
        }
        imageAlign="right"
        image={getImage(data.mosaicRight2)!}
        quote={quotes.dave3}
      />
      <LeadBlock
        paddingTop={{ xs: 32, md: 64 }}
        heading="Enterprise-grade features meet lightweight setup"
        content="Swarmia combines the best of both worlds: capabilities that meet enterprise expectations with fast and flexible configuration."
      />
      <Block
        paddingTop={responsiveScale(32)}
        paddingBottom={responsiveScale(92)}
      >
        <Stack
          maxWidth="largeBlock"
          space={gridSpacing}
          justifyContent="center"
          css={{ margin: 'auto' }}
        >
          <Row
            width="100%"
            space={gridSpacing}
            flexDirection={{ xs: 'column', md: 'row' }}
          >
            <Box flex={1}>
              <Blurb
                title="Built to scale"
                text={
                  <>
                    Swarmia fits the needs of modern organizations
                    <Br /> from startups to enterprises.
                  </>
                }
                image={getImage(data.scale)}
                isLarge
              />
            </Box>
            <Box flex={1}>
              <Blurb
                title="Easy to integrate and scale"
                text="Swarmia works with the tools your teams are already using: from issue tracker to source code hosting and chat."
                image={getImage(data.integrations)}
                isLarge
              />
            </Box>
          </Row>
          <Row space={gridSpacing} flexDirection={{ xs: 'column', md: 'row' }}>
            <Row
              space={gridSpacing}
              flex={1}
              flexDirection={{
                xs: 'column',
                sm: 'row',
                md: 'column',
                lg: 'row',
              }}
            >
              <Box flex={1}>
                <Blurb
                  icon={LockIcon}
                  title="Designed security-first"
                  text="Swarmia is SOC 2 Type 2 compliant. We conduct security audits twice a year to keep your data safe."
                />
              </Box>
              <Box flex={1}>
                <Blurb
                  icon={HeartIcon}
                  title="Built for the whole organization"
                  text="In addition to insights for the engineering leadership, Swarmia gives teams the tools they need to ship faster."
                />
              </Box>
            </Row>
            <Box flex={1}>
              <Blurb
                icon={FastIcon}
                title="Quick setup & time-to-value"
                text="Setting up Swarmia is easy. You can either do it yourself or get support from our customer success team. You’ll get your first insights within minutes of connecting your tools to Swarmia."
                isLarge
              />
            </Box>
          </Row>
        </Stack>
      </Block>
      <Box
        // This is needed for the FAQ styles to work
        className="main-body-old-styles"
      >
        <FactorialPromo />
        <FactorialResults />
      </Box>
      <QuoteBlock person="josep2" />
      <G2Testimonials />
      <CTABlock
        title="Swarmia is how the best engineering teams get better. Request a demo today."
        secondaryButton={null}
      />
    </Layout>
  )
}

export default EffectivenessPage
