import React from 'react'
import styled from 'styled-components'
import theme from '../../theme'
import Block from '../../components/Block'
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'

const Header = ({
  photo,
  heading,
  subheading,
  featured,
  company,
}: {
  photo: IGatsbyImageData
  heading: string
  subheading: string
  featured?: boolean
  company: string
}) => {
  return (
    <Block>
      <HeaderStyled>
        <div className="left-column">
          <h6 className="label">Customer Story</h6>
          <h1>
            {featured ? (
              <a href={`/story/${company.toLocaleLowerCase()}/`}>{heading}</a>
            ) : (
              heading
            )}
          </h1>
          <p className="subheading">{subheading}</p>
        </div>
        <div className="right-column">
          {photo ? (
            <div className="wrapper">
              <GatsbyImage
                image={photo}
                alt="happy people eating food"
                className="centered photo"
              />
            </div>
          ) : null}
        </div>
      </HeaderStyled>
    </Block>
  )
}

export default Header

const HeaderStyled = styled.div`
  display: flex;
  gap: 100px;
  justify-content: center;

  @keyframes read-story {
    from {
      transform: translateY(-5px);
    }
    to {
      transform: translateY(5px);
    }
  }

  .left-column {
    h1 {
      margin-top: 32px;
      max-width: 480px;
    }
    p.subheading {
      color: ${theme.colors.black600};
      max-width: 480px;
      margin-bottom: 24px;
    }
    .read-story {
      margin-top: 48px;
      display: flex;
      align-items: center;

      h3 {
        margin: 0;
      }

      img {
        width: 99px;
        height: 92px;
        transform: translateY(-5px);
        transition: transform 2.9s cubic-bezier(0.64, 0.57, 0.67, 1.53);
        animation: read-story 0.8s ease-in-out 0s infinite alternate;
        margin-right: 16px;
      }
    }
  }
  .right-column {
    .wrapper {
      .gatsby-image-wrapper {
        border-radius: 12px;
      }
      img {
        width: 100%;
        height: auto;
        filter: drop-shadow(0px 13.0802px 12.074px rgba(0, 0, 0, 0.05))
          drop-shadow(0px 17.1049px 38.2344px rgba(23, 23, 26, 0.05));
      }
    }
  }

  @media screen and (max-width: ${theme.breakpoints['large']}) {
    flex-direction: column;
    align-items: center;
    gap: 24px;

    .left-column {
      margin-bottom: 16px;
      h1 {
        font-size: 2rem;
        margin-top: 24px;
        letter-spacing: -0.05rem;
      }
      p.subheading {
        margin-bottom: 24px;
      }
      .read-story {
        margin-top: 24px;
        span {
          font-size: 1.4rem;
        }
        img {
          width: 48px;
          height: 45px;
        }
      }
    }
    .right-column {
      display: flex;
      div {
        right: 0;
      }
    }
  }
`
